<template>
  <div
      :class="{'cloned-nav is-active': scrolled}"
      class="static-nav is-expanded"
  >
    <div class="nav-start" :class="{'is-hidden': searchActive}">

      <div v-if="false" class="nav-item mobile-item is-flex">
        <button class="menu-icon v-2 mobile-sidebar-trigger is-hidden-desktop"
                @click="$store.commit('activateMenu')">
          <span></span>
        </button>
      </div>

      <!--            <search-nav></search-nav>-->
    </div>
    <div class="nav-end"  :class="{'is-hidden': searchActive}">

      <!--            <div class="nav-item mobile-item is-flex mobile-search-trigger"-->
      <!--                @click="$store.dispatch('activeFieldSearch')"-->
      <!--            >-->
      <!--                <b-icon-->
      <!--                    icon="search"-->
      <!--                >-->

      <!--                </b-icon>-->
      <!--            </div>-->
      <div class="navbar-item">
        <div class="title">
          <span>{{ Date() | date('LL') }}</span>
        </div>
      </div>
      <div class="navbar-item">
        <div class="title">
          <span>&nbsp;&nbsp;</span>
          <digital-clock :blink="true" :twelve-hour="true"></digital-clock>
        </div>
      </div>
      <branch-office-nav class="is-hidden-mobile"></branch-office-nav>
      <div class="navbar-item is-icon">
        <a class="icon-link" @click="$store.commit('toggleMenuApps')">
          <i class="fa fa-th"></i>
        </a>
      </div>

      <!--            <div class="dropdown-wrap is-right">-->
      <!--                <span class="indicator"></span>-->
      <!--                <button class="dropdown-button">-->
      <!--                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>-->
      <!--                </button>-->
      <!--                <div class="drop-menu">-->
      <!--                    <div class="menu-inner">-->
      <!--                        <div class="menu-header">-->
      <!--                            <h3>Messages</h3>-->
      <!--                            <a>All</a>-->
      <!--                        </div>-->
      <!--                        <div class="message-items">-->
      <!--                            <div class="message-item">-->
      <!--                                <img class="avatar" src="assets/img/avatars/5.jpg" alt="">-->
      <!--                                <div class="message-content">-->
      <!--                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit...</p>-->
      <!--                                    <small>30 minutes ago</small>-->
      <!--                                </div>-->
      <!--                            </div>-->
      <!--                            <div class="message-item">-->
      <!--                                <div class="avatar is-fake is-primary">-->
      <!--                                    <span>M</span>-->
      <!--                                </div>-->
      <!--                                <div class="message-content">-->
      <!--                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit...</p>-->
      <!--                                    <small>1 hour ago</small>-->
      <!--                                </div>-->
      <!--                            </div>-->
      <!--                            <div class="message-item is-read">-->
      <!--                                <img class="avatar" src="assets/img/avatars/1.jpg" alt="">-->
      <!--                                <div class="message-content">-->
      <!--                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit...</p>-->
      <!--                                    <small>Yesterday</small>-->
      <!--                                </div>-->
      <!--                            </div>-->
      <!--                        </div>-->
      <!--                    </div>-->
      <!--                </div>-->
      <!--            </div>-->
      <notification-nav></notification-nav>
      <profile-nav></profile-nav>
    </div>

    <div class="mobile-search is-hidden-desktop"
         :class="{'is-active': searchActive}"
    >
      <search-nav-mobile></search-nav-mobile>
    </div>
  </div>
</template>

<script>
import ProfileNav from "./ProfileNav";
import SearchNav from "./nav/SearchNav";
import SearchNavMobile from "./nav/SearchNavMobile";
import NotificationNav from "./NotificationNav";
import BranchOfficeNav from "./nav/BranchOfficeNav";
import DigitalClock from "vue-digital-clock";
export default {
  name: "MainNavBar",
  components: {BranchOfficeNav, NotificationNav, SearchNavMobile, SearchNav, ProfileNav, DigitalClock},
  data() {
    return {
      scroll: 0
    }
  },
  computed: {
    searchActive() {
      return this.$store.state.utils.activeSearch;
    },
    scrolled() {
      return this.scroll > 100;
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll (event) {
      this.scroll = window.scrollY;
    }
  }
}
</script>

<style scoped>

</style>
