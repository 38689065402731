<template>
  <div
    class="plex-sidebar"
    :class="{'is-inactive is-mobile-active': activeMenu, 'is-inactive': compressActive}"
    v-click-outside="onClickOutside"
  >
    <div class="sidebar-header">
      <router-link
              class="mx-auto"
              :to="{name:'home'}"
      >
        <template v-if="this.$auth.user()">
          <div>
            <img src="@/assets/logo.svg"
                 alt="Logo" class="logo"
                 v-if="(this.$auth.user().data_branch_office || false) &&(!this.$auth.user().data_branch_office.group || this.$auth.user().data_branch_office.group === 'LAPI')">
            <img src="@/assets/tadeo/icon.png" alt="Logo" class="logo"
                 v-else-if="(this.$auth.user().data_branch_office || false) && (this.$auth.user().data_branch_office.group && this.$auth.user().data_branch_office.group === 'TADEO')">
          </div>
        </template>
      </router-link>
      <button class="menu-icon v-5 is-hidden-touch" @click="$store.commit('compressedMenu')">
        <span></span>
      </button>
      <button class="menu-icon v-5 is-open mobile-item is-block is-hidden-desktop mobile-sidebar-trigger" @click="$store.commit('compressedMenu')">
        <span></span>
      </button>
    </div>
    <account-user
    ></account-user>
    <div class="sidebar-menu has-slimscroll" style="max-height: 100%;overflow: auto">
      <router-link
        class="menu-item"
        v-for="item in links"
        :to="item.route"
        :key="item.name"
        :exact="item.exact"
        v-if="$auth.check(item.auth) && ($alfalab.allowOnlyLapi($auth.user(), item)) && ($alfalab.enableByKey($auth.user(), item)) || ($auth.user().rol && $auth.user().rol === 'ADMIN')"
      >
        <b-icon :icon="item.icon"></b-icon>
        <span>{{ $t(`account.menu.links.${item.name}`) }}</span>
      </router-link>
      <div class="naver"></div>
    </div>
  </div>
</template>

<script>
  import AccountUser from "./account/AccountUser";
  export default {
    name: "MainSideBar",
    components: {AccountUser},
    computed: {
      activeMenu() {
        return this.$store.state.utils.activeMenu;
      },
      compressActive() {
        return this.$store.state.utils.compressMenu;
      },
      links() {
        if(this.$auth.user().active_branch_office.toString() === '15'){
          return this.$store.state.menu.linksGuardiaNacional;
        }
        return this.$store.state.menu.links
      }
    },
    methods: {
      onClickOutside() {
        if (this.activeMenu) {

        }
      }
    }
  }
</script>

<style scoped>

</style>
