export default {
    data() {
        return {
            loading: false,
            q: '',
        }
    },
    methods: {
        onSearch() {
            if (this.q.length > 3) {
                this.loading = true;
                this.$http.get('medicos', {
                    params: {
                        q: this.q
                    }
                }).then(({data}) => {

                })
            }
        }
    }
}