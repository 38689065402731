<template>
  <div class="dropdown-wrap is-right"
       v-click-outside="disableToggle"
       :class="{'is-active': toggle}"
  >
    <span class="indicator"></span>
    <button class="dropdown-button has-image"
            type="button"
            @click="toggle = !toggle"

    >
      <img
          :src="user.avatar || '/img/avatars/user_male_1.png'"
          :alt="`Avatar ${user.name}`"
      >
    </button>
    <div class="drop-menu">
      <div class="menu-inner">
        <div class="menu-header">
          <h3>{{ user.name }}</h3>
        </div>
        <div class="options-items">
          <template v-for="item in links">
            <router-link
                class="options-item"
                v-if="item.route"
                :to="item.route"
            >
              <b-icon
                  :icon="item.icon"
              ></b-icon>
              <div class="option-content">
                <span>{{ $t(`${item.label}.title`) }}</span>
                <span>{{ $t(`${item.label}.body`) }}</span>
              </div>
            </router-link>
            <a class="options-item"
               v-else
               @click="handleEvent(item.action)"
            >
              <b-icon
                  :icon="item.icon"
              ></b-icon>
              <div class="option-content">
                <span>{{ $t(`${item.label}.title`) }}</span>
                <span>{{ $t(`${item.label}.body`) }}</span>
              </div>
            </a>
          </template>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfileNav",
  data() {
    return {
      toggle: false,
      links: [
        {
          label: 'common.nav.profile.setting',
          icon: 'user-circle',
          route: {name:'profile'},
        },
        {
          label: 'common.nav.profile.logout',
          icon: 'sign-out-alt',
          route: false,
          action: 'onLogout'
        }
      ],
      channel: ''
    }
  },
  watch: {
    '$route': 'onChangeRoute'
  },
  computed: {
    user() {
      return this.$auth.user();

    }
  },
  mounted() {
    this.channel = `App.User.${this.user.id}`;
  },
  methods: {
    onChangeRoute() {
      this.disableToggle();
    },
    disableToggle() {
      this.toggle = false;
    },
    handleEvent(event) {
      try {
        this[event]();
      } catch (e) {

      }
    },
    onLogout() {
      this.$buefy.dialog.confirm({
        title: this.$t('auth.logout.title'),
        message: this.$t('auth.logout.ask'),
        confirmText: this.$t('auth.logout.continue'),
        cancelText: this.$t('auth.logout.cancel'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          this.$loader.show();
          await this.$http.get('../sanctum/csrf-cookie');
          this.$auth.logout({
            success({data}) {
              this.$loader.hide();
              this.$toast.success(data.message);
            },
            error({response}) {
              let data = response.data || {};
              this.$loader.hide();
              this.$toast.success(data.message);
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
