<template>
    <div class="field" v-if="false">
        <div class="control has-icon has-icon-right" :class="{'is-loading': loading}">
            <input @input="onSearch" v-model="q" type="text" class="input search-field" placeholder="Search for transactions, accounts...">
            <div class="form-icon">
                <b-icon icon="search"></b-icon>
            </div>
            <div class="form-icon right-icon mobile-search-trigger" :class="{'is-hidden': loading}" @click="$store.dispatch('activeFieldSearch', false)">
               <b-icon
                icon="times"
               ></b-icon>
            </div>
            <div class="search-results has-slimscroll"></div>
        </div>
    </div>
</template>

<script>
    import search from '@/mixins/search-mixin';

    export default {
        name: "SearchNavMobile",
        mixins: [search]
    }
</script>

<style scoped>

</style>