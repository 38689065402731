<template>
  <div class="modal-card" style="width: auto; overflow:visible;">
    <header class="modal-card-head">
      <p class="modal-card-title">Cambio de sucursal</p>
      <button
          type="button"
          class="delete"
          @click="$emit('close')"/>
    </header>
    <section class="modal-card-body" style="overflow: visible;">
      <select-branch-office searchable
                            ref="changeBranchOffice"
                            v-model="branchOffice"
                            autofocus
      >
      </select-branch-office>
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$emit('close')">Cerrar</button>
      <button class="button is-primary" type="button" @click="onChangeBranchOffice" :disabled="current === branchOffice">Cambiar Sucursal</button>
    </footer>
  </div>
</template>

<script>
import SelectBranchOffice from "@/components/brach-office/SelectBranchOffice";
export default {
name: "ModalChangeBranchOffice",
  components: {SelectBranchOffice},
  data() {
    return  {
      branchOffice: this.$auth.user().active_branch_office,
      current: this.$auth.user().active_branch_office,
    }
  },
  mounted() {
    this.$refs.changeBranchOffice.$refs.selectBranchOffice.focus()
  },
  methods: {
    onChangeBranchOffice() {
      this.$buefy.dialog.confirm({
        title: 'Cambiar de sucursal',
        message: `¿Estas seguro que deseas cambiar de sucursal?`,
        confirmText: 'Si, cambiar',
        cancelText: 'No, cancelar',
        type: 'is-info',
        hasIcon: true,
        onConfirm: async () => {
          this.$loader.show();
          await this.$http.post('change-branch-office', {
            branchOffice: this.branchOffice
          })
              .then(({data}) => {
                this.$toast.success(data.message);
                this.$emit('close');
                window.location.reload();
              })
              .catch(({response}) => {
            let data = response.data || {};
            this.$toast.error(data.message);
            this.$loader.hide();
          });
          await this.$auth.fetch();
          this.$loader.hide();
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
