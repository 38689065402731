<template>
    <b-field>
        <b-autocomplete
                class="search-form"
                :data="data"
                @typing="onSearch"
                :loading="loading"
                placeholder="e.g. jQuery"
                icon="search"
        >
            <template slot-scope="props">
                <router-link :to="{name: `${props.option.type}-show`, params: {code: props.option.code} }" class="search-result">
                    <img class="avatar" src="@/assets/logo.png" alt="">
                    <div class="meta">
                        <span>
                            {{ props.option.name }}
                        </span>
                        <span>{{ props.option.code }}</span>
                    </div>
                </router-link>
            </template>
            <template slot="empty">Sin resultados</template>
        </b-autocomplete>
    </b-field>
</template>

<script>
    import debounce from 'lodash/debounce'

    export default {
        data() {
            return {
                loading: false,
                data: [],
                q: '',
            }
        },
        methods: {
            onSearch: debounce(function (name) {
                if (!name.length) {
                    this.data = []
                    return
                }
                this.loading = true;
                this.$http.get('medicos', {
                    params: {
                        q: name
                    }
                })
                    .then(({ data }) => {
                        this.data = [];
                        data.data.forEach((item) => this.data.push(item))
                    })
                    .catch((error) => {
                        this.data = []
                        throw error
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }, 500)
        }
    }
</script>

<style scoped>

</style>