<template>
  <div class="notification-items">
    <div class="notification-item">
      <div class="avatar is-list-icon" v-if="data.icon">
        <b-icon :icon="data.icon"></b-icon>
      </div>
      <div class="notification-content">
        <p>
          <router-link
                  v-if="data.route"
                  :to="data.route"
          >
            {{ data.title }}</router-link>
          <strong v-else>{{ data.title }}</strong>
          {{ data.body }}
        </p>
        <small>{{ item.created_at | date('now') }}</small>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "NotificationNavItem",
    props: {
      data: Object | Array,
      item: Object
    }
  }
</script>

<style scoped>

</style>
