<template>
  <div class="explorer-menu" :class="{'is-active': activeMenu}">
    <div class="explorer-inner">
      <div class="explorer-container">
        <!--Header-->
        <div class="explorer-header">
          <h3>Aplicaciones</h3>
          <a class="delete is-medium"  @click="$store.commit('toggleMenuApps')"></a>
        </div>
        <!--List-->
        <div class="explore-list">
          <!-- item -->
            <router-link
                    class="explore-item is-coming-soon"
                    :to="{name:'tools'}">
                <img src="@/assets/tools.png" alt="Call center">
                <span class="coming-soon">
              {{ $t('common.tools') }}
            </span>
            </router-link>
          <a class="">


          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "AppMenu",
      computed: {
        activeMenu() {
          return this.$store.state.menu.menuApps;
        }
      }
    }
</script>

<style scoped>

</style>
