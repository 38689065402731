<template>
    <div class="sidebar-profile">
        <div class="avatar-container">
            <div class="avatar-wrapper">
                <div class="avatar">
                    <img
                            :src="user.avatar || '/img/avatars/user_male_1.png'"
                            :alt="`Avatar ${user.name}`"
                    >
                    <span class="badge"></span>
                </div>
                <h3>{{ user.name }}</h3>
                <p>{{ user.email }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AccountUser",
        computed: {
            user() {
                return this.$auth.user();
            }
        }
    }
</script>

<style scoped>

</style>