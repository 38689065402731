<template>
  <div
    v-click-outside="onClickOutNotification"
    class="dropdown-wrap is-right"
    :class="{'is-active': toggle}"
  >
    <span class="indicator" v-show="hasNotification"></span>
    <button class="dropdown-button" @click="openNotifications">
      <b-icon
        pack="far"
        icon="bell"
      ></b-icon>
    </button>
    <div class="drop-menu" style="max-width: 280px">
      <div class="menu-inner is-scrolled">
        <div class="menu-header">
          <h3>{{ $t('notification.title') }}</h3>
          <router-link
            :to="{name:'notification'}"
          >{{ $t('notification.all') }}</router-link>
        </div>
        <notification-nav-item
          v-for="item in data"
          :data="item.data"
          :item="item"
          :key="item.id"
        ></notification-nav-item>
        <infinite-loading
                @infinite="infiniteHandler"
        >
          <div slot="no-results">
            {{ $t('pagination.no_results') }}
          </div>
          <div slot="no-more">
            {{ $t('pagination.no_more') }}
          </div>
        </infinite-loading>
      </div>
    </div>
  </div>
</template>

<script>
  import NotificationNavItem from "./NotificationNavItem";
  export default {
    name: "NotificationNav",
    components: {NotificationNavItem},
    data() {
      return {
        toggle: false,
        apiUrl: 'notifications',
        params: {
          page: 1,
        }
      }
    },
    watch: {
      '$route': 'onChangeRoute'
    },
    computed: {
      data() {
        return this.$store.state.notification.notifications || [];
      },
      hasNotification() {
        return this.$store.state.notification.hasNotification || false;
      }
    },
    methods: {
      onChangeRoute() {
        this.toggle = false;
      },
      prepareComponent() {
        this.getAll()
          .then(data => {
            let items = data.data || [];
            items.forEach(el => {
              this.$store.commit('addNotification', el)
            })
          })

      },
      onClickOutNotification() {
        this.toggle = false;
      },
      openNotifications() {
        this.toggle = !this.toggle;
        this.$store.state.notification.hasNotification = false;
      },
      infiniteHandler($state) {
        this.getAll(this.params)
                .then(data => {
                  this.params.page += 1;
                  let items = data.data || [];
                  if (items.length) {
                    items.forEach(el => {
                      this.$store.dispatch('pushNotification', el)
                    })
                    $state.loaded();
                  } else {
                    $state.complete();
                  }
                })
      }
    }
  }
</script>

<style scoped>

</style>
