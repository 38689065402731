<template>
  <form @submit.prevent="handleSubmit" @input="handleInput" autocomplete="off">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Es necesario actualizar tu contraseña</p>
      </header>
      <section class="modal-card-body">

        <div class="content">
          <p>Por politicas de seguridad es necesario crear una nueva contraseña para que puedas seguir accesando a la plataforma</p>
          <p><strong>Recuerda que las contraseñas deben contener números y letras, la una longitud debe ser de 8 caracteres</strong></p>
        </div>

        <b-field label="Password" :type="errors.password ? 'is-danger' : null" :message="errors.password ? errors.password : null">
          <b-input
              type="password"
              :value="password"
              password-reveal
              placeholder="Contraseña"
              maxlength="8"
              v-model="password"
              name="password"
              pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$"
              mixlength="8"
              required>
          </b-input>
        </b-field>

        <b-field label="Confirmar contraseña" :type="errors['password-confirm'] ? 'is-danger' : null" :message="errors['password-confirm'] ? errors['password-confirm'] : null">
          <b-input
              type="password"
              password-reveal
              maxlength="8"
              v-model="passwordConfirm"
              name="password-confirm"
              mixlength="8"
              placeholder="Confirmar contraseña"
              required>
          </b-input>
        </b-field>

      </section>
      <footer class="modal-card-foot">
        <b-button
            :disabled="hasErrors"
            native-type="submit"
            label="Actualizar contraseña"
            type="is-primary" />
      </footer>
    </div>
  </form>
</template>

<script>
export default {
  name: "UpdatePasswordModal",
  data() {
    return {
      password: '',
      passwordConfirm: '',
      errors: {}
    }
  },
  computed: {
    hasErrors() {
      return (Object.keys(this.errors).length > 0 && this.errors.password !== '' && this.errors['password-confirm'] !== '') || (this.password === '' || this.password !== this.passwordConfirm);
    }
  },
  methods: {
    handleSubmit() {
      if(this.password === this.passwordConfirm && this.password.length === 8){
        this.$loader.show();
        this.$http.put('user/password', {password: this.password, password_confirmation: this.passwordConfirm}).then(() => {
          this.$emit('close');
        }).catch(errors => {
          this.$buefy.dialog.alert({
            message: errors.message
          })
        }).finally(() => {
          this.$loader.hide();
        })
      }
    },
    handleInput(event) {
      //console.log(event.target, event.target.validity.valid, event.target.validationMessage);
      if(!this.errors[event.target.name]){
        this.errors[event.target.name] = '';
      }
      if(!event.target.validity.valid){
        this.errors[event.target.name] = event.target.validationMessage;
      }else if(event.target.name === 'password-confirm' && this.password !== this.passwordConfirm){
        this.errors['password-confirm'] = 'Las contraseñas no coinciden';
      }else{
        this.errors[event.target.name] = '';
      }
      this.errors = {...this.errors};
    }
  }
}
</script>

<style scoped>

</style>