<template>
  <a class="navbar-item" @click="onSelectBranchOffice">
    <b-icon icon="building"></b-icon>
    <span>{{ branchOffice }}</span>
  </a>
</template>

<script>
import {mapState} from 'vuex';
import ModalChangeBranchOffice from "@/components/brach-office/ModalChangeBranchOffice";

export default {
  name: "BranchOfficeNav",
  props: {
    primaryKey: {
      type: String,
      default: 'name'
    }
  },
  computed: {
    ...mapState({
      branchOffices: state => state.main.accountBranchOffices
    }),
    branchOffice() {
      let branchOffice = this.branchOffices.find(el => el.id === this.$auth.user().active_branch_office) || {};
      return branchOffice[this.primaryKey];
    }
  },
  methods: {
    onSelectBranchOffice() {
      this.$buefy.modal.open({
        parent: this,
        component: ModalChangeBranchOffice,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true
      })
    }
  }
}
</script>

<style scoped>

</style>